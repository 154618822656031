
.btn-circle.btn-sm { 
  width: 30px; 
  height: 30px; 
  padding: 0px 0px; 
  border-radius: 15px; 
  font-size: 14px; 
  text-align: center; 
} 
.btn-circle.btn-md { 
  width: 50px; 
  height: 50px; 
  padding: 0px 0px; 
  border-radius: 25px; 
  font-size: 16px; 
  text-align: center; 
} 
.btn-circle.btn-xl { 
  width: 110px; 
  height: 110px; 
  padding: 0px 0px; 
  border-radius: 55px; 
  font-size: 40px; 
  text-align: center; 
}
@media screen and (max-width: 450px){
  .btn-circle.btn-xl { 
    width: 60px; 
    height: 60px; 
    padding: 0px 0px; 
    border-radius: 30px; 
    font-size: 18px; 
    text-align: center; 
  } 
}


.leaderboard-name-current { 
  transition: color 0.5s ease,
              background-color 1.0s ease;
  font-size: 40px; 
  color: white;
  background-color:  #007bff !important;; 
}

.leaderboard-name { 
  font-size: 40px; 
}


.leaderboard-score-current { 
  transition: background-color 1.0s ease;
  font-size: 80px; 
  text-align: center;
  background-color: #cae3ff; 
}

.leaderboard-score { 
  font-size: 80px; 
  text-align: center; 
}

@media screen and (max-width: 450px){
  .leaderboard-name-current { 
    transition: color 0.5s ease,
                background-color 1.0s ease;
    font-size: 25px; 
    color: white;
    background-color:  #007bff !important;; 
  }
  
  .leaderboard-name { 
    font-size: 25px; 
  }
  
  
  .leaderboard-score-current { 
    transition: background-color 1.0s ease;
    font-size: 40px; 
    text-align: center;
    background-color: #cae3ff; 
  }
  
  .leaderboard-score { 
    font-size: 40px; 
    text-align: center; 
  }
}